import { createSlice } from "@reduxjs/toolkit";
import { fetchUsers, exportUsers } from "./Utils/UserUtils";
import { init } from "echarts";

const initialFilters = {
	"filter[id]": "",
	"filter[name]": "",
	"filter[username]": "",
	"filter[email]": "",
	"filter[gender]": "",
	"filter[phone]": "",
	"filter[address]": "",
	"filter[order.status]": "",
	"filter[order.payment_method]": "",
	"filter[membership.title]": "",
	"filter[membership.id]": "",
	"filter[referral_code]": "",
	sort: "",
	include: "order,order.membership,membership,parentUser,membershipUpgrade",
	page: "",
};

const UserSlice = createSlice({
	name: "Users",
	initialState: {
		filters: initialFilters,
		data: [],
		detail: {},
		isLoading: false,
		isExport: false,
	},
	reducers: {
		setFilters: (state, action) => {
			state.filters = action.payload;
		},
		setData: (state, action) => {
			state.data = action.payload;
		},
		setDetail: (state, action) => {
			state.detail = action.payload;
		},
		resetFilters: (state) => {
			state.filters = initialFilters;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUsers.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.isLoading = false;
				// console.log('status fulfilled', action.payload)
				state.data = action.payload;
				// console.log('data', state.data)
			})
			.addCase(fetchUsers.rejected, (state) => {
				state.isLoading = false;
			})
			.addCase(exportUsers.pending, (state) => {
				state.isExport = true;
			})
			.addCase(exportUsers.fulfilled, (state) => {
				state.isExport = false;
			})
			.addCase(exportUsers.rejected, (state) => {
				state.isExport = false;
			});
	},
});

export const { setFilters, setData, setDetail } = UserSlice.actions;

export default UserSlice.reducer;
