import { createAsyncThunk } from '@reduxjs/toolkit';
import { SubMateriService } from '../../../../Services/axios/SubMateri.Service';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import { handleApiError } from '../../../../Features/Common/ErrorHandler';

export const fetchSubMaterial = createAsyncThunk(
  'SubMaterial/fetchSubMaterial',
  async (filters) => {
    try {
      const response = await SubMateriService.getAll(filters);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }
);

export const submitSubMaterial = createAsyncThunk(
    'addSubMaterial/submitSubMaterial',
    async (data) => {
      try {
        const formData = new FormData();
        formData.append('type', data.type);
        formData.append('name', data.name);
        formData.append('description', data.description);
        if(data.type === 'ebook' || data.type === 'youtube'){
        data.link && formData.append('link', data.link);
        }
        if(data.type === 'image' || data.type === 'video' || data.type === 'audio'){
          data.file &&  formData.append('uploaded_file', data.file);
        }
        data.duration && formData.append('duration', data.duration);
        let response;
        if(data.isUpdate){
          response = await SubMateriService.update(data.id, formData);
        } else {
          response = await SubMateriService.create(formData);
        }
        toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
        });
        data.successCallback && data.successCallback();
        return response.data.data;
      } catch (error) {
        for (const errorField in error.response.data.errors) {
          if (error.response.data.errors.hasOwnProperty(errorField)) {
            const errorMessages = error.response.data.errors[errorField];
            let errorMessage = errorMessages[0];
            toast.error(errorMessage, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
        if (error.code === "ERR_NETWORK") {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
          });
        } else if (error.response.status === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
          });
        }
      }
    }
);

export const deleteSubMaterial = createAsyncThunk(
  'deleteSubMaterial/deleteSubMaterial',
  async (id) => {
    try {
      const response = await SubMateriService.delete(id);
      toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
      });
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.data) {
          toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
          });
      }
    }
  }
);

export const attachDetachSubMaterial = createAsyncThunk(
  'attachDetachSubMaterial/attachDetachSubMaterial',
  async (data) => {
    try {
      const response = await SubMateriService.attachDetach(data.method, data.type, data.idSubmateri, data.idTryout);
      return true;
    } catch (error) {
      if (error.response && error.response.data) {
          toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
          });
      }
    }
  }
);