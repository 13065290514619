import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { handleReplicateTryoutDialog } from "./TryoutUtils";
import Select from "react-select";

const DialogDuplicate = ({
	isOpen,
	setOpenModal,
	item,
	category,
	dispatch,
	setRefetch,
}) => {
	const [categoryParents, setCategoryParents] = useState([]);
	const [categoryChilds, setCategoryChilds] = useState([]);
	const [selectedCategoryChild, setSelectedCategoryChild] = useState(null);
	const [selectedCategoryChildLabel, setSelectedCategoryChildLabel] = useState(null);

	useEffect(() => {
		if (!category?.data?.length) return;
		const parents = category.data.filter((category) => category.parent_id === null);
		parents.forEach((parent) => {
			const updatedParent = { ...parent, label: parent.title, value: parent.id };
			setCategoryParents((prevParents) => [...prevParents, updatedParent]);
		})
	}, [category]);

	const handleSelectParent = (selectedOption) => {
		setCategoryChilds([]);

		const parent = category.data.filter((category) => category.id === selectedOption.value);
		const children = parent[0].children;
		children.forEach((child) => {
			const updatedChild = { ...child, label: child.title, value: child.id };
			setCategoryChilds((prevChildren) => [...prevChildren, updatedChild]);
		})
	}

	const handleSelectChild = (selectedOption) => {
		setSelectedCategoryChild(selectedOption.value);
		setSelectedCategoryChildLabel(selectedOption.label);
	}

	const handleSubmit = () => {
		handleReplicateTryoutDialog(dispatch, item, setRefetch, selectedCategoryChild, selectedCategoryChildLabel);
	}

	return (
		<Modal isOpen={isOpen} size="lg">
			<ModalHeader>Duplikat Tryout</ModalHeader>
			{item && (
				<ModalBody>
					<p>
						Duplikat Tryout <strong>{item.title}</strong>.
					</p>
					<div className="form-group">
						<h6>Kategori Tryout Baru</h6>
						<Select
							options={categoryParents}
							className="js-example-basic-single"
							isMulti={false}
							isClearable={false}
							placeholder="Pilih Kategori Tryout"
							onChange={handleSelectParent}
						/>
						<br />
						<h6>Kategori Semua Soal</h6>
						<Select
							options={categoryChilds}
							className="js-example-basic-single"
							isMulti={false}
							isClearable={true}
							placeholder="Pilih Kategori Soal"
							onChange={handleSelectChild}
						/>
					</div>
				</ModalBody>
			)}
			<ModalFooter>
				<Button color="warning" onClick={() => setOpenModal(false)}>
					Close
				</Button>
				<Button
					color="primary"
					onClick={() => {
						handleSubmit();
						setOpenModal(false);
					}}>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DialogDuplicate;
