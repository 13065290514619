import React, { useState } from 'react';

const DualListBox = () => {
  const [availableOptions, setAvailableOptions] = useState([
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4',
    'Option 5',
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const moveRight = () => {
    const selected = availableOptions.filter((option, index) =>
      document.getElementById(`available-${index}`).checked
    );
    setSelectedOptions([...selectedOptions, ...selected]);
    setAvailableOptions(
      availableOptions.filter((option) => !selected.includes(option))
    );
	deselectAll();
  };

  const moveLeft = () => {
    const selected = selectedOptions.filter((option, index) =>
      document.getElementById(`selected-${index}`).checked
    );
    setAvailableOptions([...availableOptions, ...selected]);
    setSelectedOptions(
      selectedOptions.filter((option) => !selected.includes(option))
    );
	deselectAll();
  };

  const deselectAll = () => {
	document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
	  checkbox.checked = false;
	});
  };

// add function for select All available and select all selected

	const selectAllAvailable = () => {
		const isSelectAll = document.getElementById('available-all').checked;
		if (isSelectAll) {
			availableOptions.forEach((option, index) => {
				document.getElementById(`available-${index}`).checked = true;
			});
		} else {
			deselectAll();
		}
	};

	const selectAllSelected = () => {
		const isSelectAll = document.getElementById('selected-all').checked;
		if (isSelectAll) {
			selectedOptions.forEach((option, index) => {
				document.getElementById(`selected-${index}`).checked = true;
			});
		} else {
			deselectAll();
		}
	}

  return (
    <div className="container">
      <div className="row">
        {/* Available Options */}
        <div className="col-md-5">
          <h5>Available</h5>
          <ul className="list-group">
			<li className="list-group-item">
				<input
				  type="checkbox"
				  id="available-all"
				  className="form-check-input me-2"
				  onClick={selectAllAvailable}
				/>
				<label htmlFor="available-all" class="text-secondary">Select All</label>
			</li>
            {availableOptions.map((option, index) => (
              <li className="list-group-item" key={index}>
                <input
                  type="checkbox"
                  id={`available-${index}`}
                  className="form-check-input me-2"
                />
				<label htmlFor={`available-${index}`}>{option}</label>
              </li>
            ))}
          </ul>
        </div>

        {/* Buttons */}
        <div className="col-md-2 d-flex flex-column justify-content-center align-items-center">
          <button
            className="btn btn-primary mb-2"
            onClick={moveRight}
            disabled={availableOptions.length === 0}
          >
            &gt;&gt;
          </button>
          <button
            className="btn btn-secondary"
            onClick={moveLeft}
            disabled={selectedOptions.length === 0}
          >
            &lt;&lt;
          </button>
        </div>

        {/* Selected Options */}
        <div className="col-md-5">
          <h5>Selected</h5>
          <ul className="list-group">
			<li className="list-group-item">
				<input
				  type="checkbox"
				  id="selected-all"
				  className="form-check-input me-2"
				  onClick={selectAllSelected}
				/>
				<label htmlFor="selected-all" class="text-secondary">Select All</label>
			</li>
            {selectedOptions.map((option, index) => (
              <li className="list-group-item" key={index}>
                <input
                  type="checkbox"
                  id={`selected-${index}`}
                  className="form-check-input me-2"
                />
				<label htmlFor={`selected-${index}`}>{option}</label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DualListBox;
