 import { createAsyncThunk } from '@reduxjs/toolkit';
import { ScheduleServices } from '../../../../Services/axios/Schedule.Service';
import { checkFilter } from '../../../../Features/Common/CommonUtils';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { handleApiError } from '../../../../Features/Common/ErrorHandler';

export const fetchSchedules = createAsyncThunk(
    'Schedules/fetchSchedules',
    async (filters) => {
        try {
            let filter = checkFilter(filters);
            const response = await ScheduleServices.getAll(filter);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    }
);

export const fetchQRCode = createAsyncThunk(
    'Schedules/fetchQRCode',
    async () => {
        try {
            const response = await ScheduleServices.QRCodePresence();
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    }
);

export const fetchDetailMemberClassSchedule = createAsyncThunk(
    'Schedules/fetchDetailMemberClassSchedule',
    async ({classId, userId}) => {
        try {
            const response = await ScheduleServices.getDetailMemberClassSchedule(classId, userId);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    }
);

export const editAttendance = createAsyncThunk(
    'Schedules/editAttendance',
    async ({ attendanceId, is_attended }) => {
        try {
            const formData = new FormData();
            formData.append('is_attended', is_attended);
            await ScheduleServices.editAttendance(attendanceId, formData);
            return true;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return false;
        }
    }
);

export const submitSchedules = createAsyncThunk(
    'Schedules/submitSchedules',
    async (data) => {
        try {
            const formData = new FormData();
            formData.append('class_id', data.class_id);
            formData.append('tentor_id', data.tentor_id);
            data.course_id && formData.append('course_id', data.course_id);
            data.material_id && formData.append('material_id', data.material_id);
            formData.append('name', data.name);
            formData.append('QR_start_at', data.QR_start_at);
            formData.append('session_start_at', data.session_start_at);
            formData.append('session_end_at', data.session_end_at);
            formData.append('place', data.place);

            let response;
            if (data.id) {
                response = await ScheduleServices.update(data.id, formData);
            } else {
                response = await ScheduleServices.create(formData);
            }
            Swal.fire({
                icon: 'success',
                title: 'Berhasil',
                text: 'Sesi berhasil ' + (data.id ? 'diubah' : 'dibuat') + '.',
            });
            data.successCallback && data.successCallback();
            return response.data.data;
        } catch (error) {
            for (const errorField in error.response.data.errors) {
                if (error.response.data.errors.hasOwnProperty(errorField)) {
                    const errorMessages = error.response.data.errors[errorField];
                    let errorMessage = errorMessages[0];
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
            if (error.code === "ERR_NETWORK") {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else if (error.response.status === 500) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
                });
            }
        }
    }
);

export const deleteSchedules = createAsyncThunk(
    'Schedules/deleteSchedules',
    async (id) => {
        try {
            const response = await ScheduleServices.delete(id);
            Swal.fire({
                icon: 'success',
                title: 'Berhasil',
                text: 'Sesi berhasil dihapus.',
            });
            return response.data.data;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }
);

export const attachDetachUserToSchedule = createAsyncThunk(
    'Schedules/attachDetachUserToSchedule',
    async (data) => {
        try {
            const formData = new FormData();
            data.userId.forEach((user, index) => {
                formData.append(`user[${index}]`, user);
            });
            await ScheduleServices.attachDetachUserToSchedule(data.scheduleId, formData);
            return true;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return false;
        }
    }
);