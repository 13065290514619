import { createAsyncThunk } from '@reduxjs/toolkit';
import { BankQuestionService } from '../../../../Services/axios/BankQuestion.Service';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import { handleApiError } from '../../../../Features/Common/ErrorHandler';

export const fetchQuestion = createAsyncThunk(
  'Question/fetchQuestion',
  async (filters) => {
    try {
      const response = await BankQuestionService.getAll(filters);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }
);

export const submitQuestion = createAsyncThunk(
  'addQuestion/submitQuestion',
  async (data) => {
    try {
      const formData = new FormData();
      formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('type', data.type);
      formData.append('answer_explanation', data.answer_explanation);
      formData.append('answer_explanation_video', data.answer_explanation_video);
      formData.append('audio_source', data.audio_source);
      // Handle choice & answer as array 
      if (data.type === 'fill_in_the_blanks') {
        data.answer.forEach((answers, index) => {
          formData.append(`answer[${index}]`, answers);
        });
      } else if (data.type === 'sentence_arrangement' || data.type === 'multiple_choice'){
        data.answer.forEach((answers, index) => {
          formData.append(`answer[${index}]`, answers);
        });
        data.choice.forEach((choices, index) => {
          if (choices.trim() !== '') {
            formData.append(`choice[${index}]`, choices);
          } 
        });
      }
      let response;
      if (data.isUpdate) {
        response = await BankQuestionService.update(data.id, formData);
      } else {
        response = await BankQuestionService.create(formData);
      }
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      data.successCallback && data.successCallback();
      return response.data.data;
    } catch (error) {
      for (const errorField in error.response.data.errors) {
        if (error.response.data.errors.hasOwnProperty(errorField)) {
          const errorMessages = error.response.data.errors[errorField];
          let errorMessage = errorMessages[0];
          if (errorField === "answer_explanation") {
            errorMessage = "Penjelasan kunci jawaban harus diisi dengan benar.";
          } else if (errorField === "choice") {
            errorMessage = "Pilihan jawaban harus diisi dengan benar.";
          } else if (errorField === "answer" && data.type === 'multiple_choice') {
            errorMessage = "Status pilihan jawaban harus diisi dengan benar.";
          }
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
      if (error.code === "ERR_NETWORK") {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
        });
      }
    }
  }
);


export const deleteQuestion = createAsyncThunk(
  'deleteQuestion/deleteQuestion',
  async (id) => {
    try {
      const response = await BankQuestionService.delete(id);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }
);

export const importQuestion = createAsyncThunk(
  'importQuestion/importQuestion',
  async (data) => {
    try {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('countChoices', data.countChoices);
      const response = await BankQuestionService.import(formData);
      Swal.fire({
        icon: 'success',
        title: 'Sukses',
        text: 'Import soal berhasil.',
      });
      data.successCallback && data.successCallback();
      return response.data.data;
    } catch (error) {
      console.log(error, "error import")
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Terjadi kesalahan saat mengunggah data. Mohon coba lagi nanti.',
      });
    }
  }
);
