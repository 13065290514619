import Swal from 'sweetalert2';
import { toast } from "react-toastify";

export const handleApiError = (error) => {
    let errorMessage = 'Terjadi kesalahan. Mohon coba lagi nanti.';
    let errorTitle = 'Error';

    if (error.response) {
        const statusCode = error.response.status;

        // Tentukan judul dan pesan error berdasarkan status code
        switch (statusCode) {
            case 400:
                errorMessage = 'Permintaan tidak valid.';
                errorTitle = 'Bad Request';
                break;
            case 401:
                errorMessage = 'Anda tidak diotorisasi untuk mengakses ini.';
                errorTitle = 'Unauthorized';
                break;
            case 403:
                errorMessage = 'Anda tidak memiliki izin untuk mengakses ini.';
                errorTitle = 'Forbidden';
                break;
            case 404:
                errorMessage = 'Sumber daya tidak ditemukan.';
                errorTitle = 'Not Found';
                break;
            case 422: // Handle error validasi
                const errors = error.response.data.errors;
                for (const field in errors) {
                    if (errors.hasOwnProperty(field)) {
                        const fieldErrors = errors[field];
                        toast.error(fieldErrors[0], {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                }
                return; // Keluar tanpa menampilkan Swal karena sudah ditangani
            case 429:
                errorMessage = 'Terlalu banyak permintaan. Coba lagi 1 menit lagi.';
                errorTitle = 'Too Many Requests';
                break;
            case 500:
                errorMessage = 'Terjadi kesalahan internal server. Mohon hubungi pengembang.';
                errorTitle = 'Internal Server Error';
                break;
            default:
                errorMessage = 'Terjadi kesalahan. Mohon coba lagi nanti.';
                errorTitle = 'Error';
                break;
        }

        Swal.fire({
            icon: 'error',
            title: errorTitle,  // Menampilkan judul error spesifik
            text: errorMessage, // Menampilkan pesan error
        });
    } else if (error.request) {
        Swal.fire({
            icon: 'error',
            title: 'Network Error',
            text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
        });
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Terjadi kesalahan. Mohon coba lagi nanti.',
        });
    }

    throw error;  // Lempar error untuk penanganan lebih lanjut jika diperlukan
};

