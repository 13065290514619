import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import { Btn, H6, Spinner } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PostCommon from '../../../Component/Learning/Common/Form/Post';
import RichEditor from '../../../Component/Editor/RichEditor/RichEditor';
import DropzoneCommon from '../../../Component/Learning/Common/Upload/Dropzone';
import { submitMainProgram } from '../../../Features/Learning/MainPrograms/Utils/MainProgramUtils';
import { setField, resetState } from '../../../Features/Learning/MainPrograms/addMainProgramSlice';
import { getMainProgramCategory } from '../../../Features/Learning/Categories/Utils/CategoriesUtils';
import Swal from 'sweetalert2';


const UserPostContain = (props) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const data = useSelector(state => state.addMainProgram)
    const startUpload = useSelector(state => state.addMainProgram.isLoading);
    const isUpdate = useSelector(state => state.addMainProgram.isUpdate);
    const [selectedValue, setSelectedValue] = useState(null);
    const [dataCategory, setDataCategory] = useState([]);
    const [addPoint, setAddPoint] = useState(false);
    const pointLength = [1, 2, 3, 4, 5, 6];
    const [point, setPoint] = useState([]);

    const handleDelete = (e) => {
        e.preventDefault();
        setPoint([]);
        dispatch(setField({ field: 'point', value: [] }));
    }

    const handleDeletePDF = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Anda tidak dapat mengembalikan Lampiran yang telah dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(setField({ field: 'attachment', value: 'delete' }));
                dispatch(setField({ field: 'attachmentLink', value: null }));
            }
        })
    }

    const handleChangePoint = (e, index) => {
        const { name, value } = e.target;
        const updatedPoint = [...data.point];
        updatedPoint[index] = { ...updatedPoint[index], [name]: value };
        setPoint(updatedPoint);
        // console.log(value, 'updatedOptions')
        const nonEmptyArray = updatedPoint.filter(item =>
            (item.point_title && item.point_title.trim() !== "") ||
            (item.point_desc && item.point_desc.trim() !== "")
        );
        dispatch(setField({ field: 'point', value: nonEmptyArray }));
        // console.log(nonEmptyArray, 'nonEmptyArray')
    }

    const handleSuccess = () => {
        history(`${process.env.PUBLIC_URL}/main-program`);
        dispatch(resetState());
    };

    const validateForm = () => {
        if (!data.title) return 'Judul tidak boleh kosong.';
        if (!data.header_desc) return 'Deskripsi header tidak boleh kosong.';
        if (!data.content_desc) return 'Deskripsi content tidak boleh kosong.';
        if (!data.category_id) return 'Kategori tidak boleh kosong.';
        if (!data.order) return 'Urutan program tidak boleh kosong.';
        if (!isUpdate && !data.image) return 'Banner tidak boleh kosong.';
        if (!isUpdate && data.is_tryout_only === '') return 'Anda belum memilih membership hanya berisi tryout atau tidak.';
        return null;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errorMessage = validateForm();

        if (errorMessage) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorMessage,
            });
        } else {
            // console.log(data, 'data');
            dispatch(submitMainProgram({ ...data, successCallback: handleSuccess }));
        }
    };


    const handleDiscard = (e) => {
        e.preventDefault();
        dispatch(resetState());
        history(`${process.env.PUBLIC_URL}/main-program`);
    };

    const handleChanges = (e) => {
        const { name, value } = e.target;
        console.log(name, value, 'name value')
        if (name === 'video_promo') {
            if (value === '') {
                dispatch(setField({ field: name, value: '' }));
            } else {
                dispatch(setField({ field: name, value: value }));
            }
        } else {
            dispatch(setField({ field: name, value: value }));
        }
    }

    const flattenAllData = (data) => {
        const flattened = [];

        data.forEach((category) => {

            if (category.children) {
                category.children.forEach((child) => {
                    flattened.push({ label: child.name, value: child.id });
                });
            }
        });

        return flattened;
    };

    useEffect(() => {
        let isMounted = true; // Flag untuk mengecek apakah komponen masih mounted

        dispatch(getMainProgramCategory())
            .then((response) => {
                if (isMounted) { // Update state hanya jika komponen masih mounted
                    const flattenedData = flattenAllData(response.payload.data);
                    setDataCategory(flattenedData);
                }
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });

        window.scrollTo(0, 0);

        return () => {
            isMounted = false; // Set flag ke false saat komponen di-unmount
        };
    }, []);

    const handleChangeBatch = (e) => {
        if (e === '<p> </p>' || e === '<p><br/></p>' || e === '<p><br></p>' || e === '<p></p>') {
            dispatch(setField({ field: 'batch', value: '' }));
        } else {
            dispatch(setField({ field: 'batch', value: e }));
        }
    }

    const handleSelectChange = (selectedOption) => {
        // console.log(selectedOption, 'selectedOption')
        setSelectedValue(selectedOption);
        if (selectedOption === null) {
            dispatch(setField({ field: 'category_id', value: '' }));
        } else {
            dispatch(setField({ field: 'category_id', value: selectedOption.value}));
        }
    }

    const handleChangeUpload = ({ meta, file }, status) => {
        if (file.type === 'application/pdf') {
            dispatch(setField({ field: 'attachment', value: file }));
        } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
            dispatch(setField({ field: 'image', value: file }));
        }
        // console.log('Image upload is :', data.file);
        console.log(file.type,'type')
        console.log(status, 'status')
    };

    useEffect(() => {
        const selectedCategory = data.category_id
            ? [{
                value: data.category_id.id,
                label: data.category_id.title
            }]
            : selectedValue;
        setSelectedValue(selectedCategory);
    }, [data.id]);

    return (
        <PostCommon title={props.label} handleSubmit={handleSubmit} handleDiscard={handleDiscard} >
            {startUpload ? (
                <Col lg="12">
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-30' }} />
                    </div>
                    <H6 attrH6={{ className: 'sub-title mb-0 text-center' }}>{'Sedang proses unggah...'}</H6>
                </Col>
            ) : (
                <div>
                    <Col sm="12">
                        <FormGroup className='mb-3'>
                            <Label for="title" style={{ fontWeight: 'bold' }}>Judul Main Program :</Label>
                            <Input className="form-control" id="title" type="text" name="title" placeholder="Masukkan judul program..."
                                value={data.title}
                                onChange={handleChanges} />
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label for="header_desc" style={{ fontWeight: 'bold' }}>Deskripsi Header :</Label>
                            <Input className="form-control" id="header_desc" type="text" name="header_desc" placeholder="Masukkan deskripsi header..."
                                value={data.header_desc}
                                onChange={handleChanges} />
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label for="content_desc" style={{ fontWeight: 'bold' }}>Deskripsi Content :</Label>
                            <Input className="form-control" id="content_desc" type="textarea" name="content_desc" placeholder="Masukkan deskripsi content..."
                                value={data.content_desc}
                                onChange={handleChanges} />
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label style={{ fontWeight: 'bold' }}>Program Hanya Berisi Tryout?</Label>
                            {data.membership && data.membership.length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    <strong>Perhatian!</strong> Anda tidak dapat mengubah status ini karena program sudah memiliki membership.
                                </div>
                            )}
                            <div className="m-checkbox-inline">
                                <Label for="is_tryout_only_false">
                                    <Input className="radio_animated" id="is_tryout_only_false" type="radio" name="is_tryout_only" value={0}
                                        checked={Number(data.is_tryout_only) === 0}
                                        onChange={handleChanges}
                                        disabled={data.membership && data.membership.length > 0} 
                                    />
                                    TIDAK
                                </Label>
                                <Label for="is_tryout_only_true">
                                    <Input className="radio_animated" id="is_tryout_only_true" type="radio" name="is_tryout_only" value={1}
                                        checked={Number(data.is_tryout_only) === 1}
                                        onChange={handleChanges}
                                        disabled={data.membership && data.membership.length > 0} 
                                    />
                                    YA
                                </Label>
                            </div>
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label style={{ fontWeight: 'bold' }}>Program Bersifat Privat?</Label>
                            <p>Jika program bersifat privat, maka program tidak akan muncul di halaman program.</p>
                            <div className="m-checkbox-inline">
                                <Label for="is_private_false">
                                    <Input className="radio_animated" id="is_private_false" type="radio" name="is_private" value={0}
                                        checked={Number(data.is_private) === 0}
                                        onChange={handleChanges}
                                    />
                                    TIDAK
                                </Label>
                                <Label for="is_private_true">
                                    <Input className="radio_animated" id="is_private_true" type="radio" name="is_private" value={1}
                                        checked={Number(data.is_private) === 1}
                                        onChange={handleChanges}
                                    />
                                    YA
                                </Label>
                            </div>
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label style={{ fontWeight: 'bold' }}>Untuk Program Kerja Sama Kampus atai Sekolah?</Label>
                            <p>Jika program ini merupakan program kerja sama kampus atau sekolah, maka program bisa custom slug.</p>
                            <div className="m-checkbox-inline">
                                <Label for="is_campus_event_false">
                                    <Input className="radio_animated" id="is_campus_event_false" type="radio" name="is_campus_event" value={0}
                                        checked={Number(data.is_campus_event) === 0}
                                        onChange={handleChanges} />
                                        TIDAK
                                </Label>
                                <Label for="is_campus_event_true">
                                    <Input className="radio_animated" id="is_campus_event_true" type="radio" name="is_campus_event" value={1}
                                        checked={Number(data.is_campus_event) === 1}
                                        onChange={handleChanges} />
                                        YA
                                </Label>
                            </div>
                        </FormGroup>
                        {Number(data.is_campus_event) === 1 && (
                            <FormGroup className='mb-3'>
                                <Label for="validationLink" style={{ fontWeight: 'bold' }}>Slug:</Label>
                                <p>User dapat mengakses program melalui brofesional.id/kampus/"slug" atau brofesional.id/sch/"slug".</p>
                                <Input className="form-control" id="validationLink" type="text" name="slug" placeholder="Masukkan slug program..." required=""
                                    value={data.slug}
                                    onChange={handleChanges} />
                            </FormGroup>
                        )}
                        <FormGroup className="mb-3">
                            <Label className="col-form-label" style={{ fontWeight: 'bold' }}>{'Pilih Kategori'}:</Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Pilih Kategori Program..."
                                isClearable
                                isSearchable
                                name="color"
                                options={dataCategory}
                                onChange={handleSelectChange}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                value={selectedValue}
                            />
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label for="order" style={{ fontWeight: 'bold' }}>Urutan Program:</Label>
                            <p>Urutan program akan menentukan posisi program di halaman program berdasarkan kategori.<br />
                            Semakin besar urutan, semakin depan posisi program.</p>
                            <Input className="form-control" id="order" type="number" name="order" placeholder="Masukkan urutan program..."
                                value={data.order}
                                onWheel={(e) => e.target.blur()}
                                onChange={handleChanges} />
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <Label for="video_promo" style={{ fontWeight: 'bold' }}>Video Promo :</Label>
                            <Input className="form-control" id="video_promo" type="text" name="video_promo" placeholder="Masukkan link video promo..."
                                value={data.video_promo ? data.video_promo : ''}
                                onChange={handleChanges} />
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            {/* <Label for="point" style={{ fontWeight: 'bold' }}>Point Pendukung Bagaimana Brofesional Membimbingmu :</Label> */}
                            <div className="m-checkbox-inline">
                                <Label for="point">
                                    <Input className="checkbox_animated" id="point" type="checkbox" name="point" value={addPoint}
                                        checked={data.isPoint}
                                        onChange={() => dispatch(setField({ field: 'isPoint', value: !data.isPoint }))} />
                                        <b>Tambahkan Point Pendukung "Bagaimana Brofesional Membimbingmu?"</b>
                                </Label>
                                {/* button delete state data.ispoint */}
                                {data.isPoint && data.point.length > 0 && (
                                    <Btn attrBtn={{ className: 'btn btn-danger' , onClick: handleDelete }}>
                                        <i className="fa fa-trash fa-lg"></i>
                                    </Btn>
                                )}
                            </div>
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            {data.isPoint && pointLength.map((item, index) =>
                                <div key={index}>
                                    <Row>
                                        <Col className='col-4'>
                                            <FormGroup className='mb-3'>
                                                <Label for="point_title" style={{ fontWeight: 'bold' }}>Judul Point {item} :</Label>
                                                <Input className="form-control" id="point_title" type="text" name="point_title" placeholder="Masukkan judul point..."
                                                    value={data.point[index] ? data.point[index].point_title : ''}
                                                    onChange={(e) => handleChangePoint(e, index)} />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className='mb-3'>
                                                <Label for="point_desc" style={{ fontWeight: 'bold' }}>Deskripsi Point {item} :</Label>
                                                <Input className="form-control" id="point_desc" type="textarea" name="point_desc" placeholder="Masukkan deskripsi point..."
                                                    value={data.point[index] ? data.point[index].point_desc : ''}
                                                    onChange={(e) => handleChangePoint(e, index)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </FormGroup>
                        {/* quill for input batch */}
                        <FormGroup className='mb-3'>
                            <Label for="batch" style={{ fontWeight: 'bold' }}>Batch :</Label>
                            <RichEditor id="batch" handleChangeDescription={handleChangeBatch} description={data.batch} />
                        </FormGroup>
                        {/* Dropzone type pdf */}
                        {data.attachmentLink && (
                            <div>
                            <FormGroup className='mb-3'>
                                <Label for="batch" style={{ fontWeight: 'bold', marginRight: '10px' }}>Preview Lampiran : </Label>
                                <Btn attrBtn={{ className: 'btn btn-danger', onClick: handleDeletePDF }}>
                                    <i className="fa fa-trash fa-lg"></i>
                                </Btn>
                            </FormGroup>
                            <iframe title="Preview lampiran" src={data.attachmentLink} width="100%" height="500px"></iframe>
                            </div>
                        )}
                        <DropzoneCommon label={'Lampiran'} inputContent={data.attachmentLink ? 'lampiran tersedia' : 'Silahkan upload lampiran file pdf'} handleChangeStatus={handleChangeUpload} accept="application/pdf" />
                        {/* Dropzone type image */}
                        {data.bannerLink && (
                            <div className="blog-page blog-box mb-3">
                                <img style={{ width: "30%" }} src={data.bannerLink} alt={data.title} />
                            </div>
                        )}
                        <DropzoneCommon label={'Banner Program'} inputContent={isUpdate ? 'banner program tersedia' : 'Silahkan upload banner program'} handleChangeStatus={handleChangeUpload} accept="image/*" />
                    </Col>
                </div>
            )}
        </PostCommon>
    );
};

export default UserPostContain;