import { createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionServices } from '../../../../Services/axios/Transaction.Service';
import { toast } from 'react-toastify';
import { handleApiError } from '../../../../Features/Common/ErrorHandler';

export const fetchTransactions = createAsyncThunk(
    'Transactions/fetchTransactions',
    async (filters) => {
        try {
            const response = await TransactionServices.getAll(filters);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    }
);

export const setOrderStatus = createAsyncThunk(
    'Transactions/setOrderStatus',
    async (data) => {
        try {
            const formData = new FormData();
            formData.append('status', data.status);
            let response;
            response = await TransactionServices.setOrderStatus(data.id, formData);
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return response.data.data
        } catch (error) {
            for (const errorField in error.response.data.errors) {
                if (error.response.data.errors.hasOwnProperty(errorField)) {
                    const errorMessages = error.response.data.errors[errorField];
                    let errorMessage = errorMessages[0];
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        }
    }
);