import { createAsyncThunk } from '@reduxjs/toolkit';
import { TryoutService } from '../../../../Services/axios/Tryout.Service';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import { handleApiError } from '../../../../Features/Common/ErrorHandler';

export const fetchTryout = createAsyncThunk(
  'Tryout/fetchTryout',
  async (filters) => {
    try {
      const response = await TryoutService.getAll(filters);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }
);

export const orderTryoutQuestion = createAsyncThunk(
  'Tryout/orderTryoutQuestion',
  async (data) => {
    try {
      await TryoutService.setOrder(data.id, data.question_id);
      
      return true;;
    } catch (error) {
      console.log('Error orderTryoutQuestion:', error);
      return false;
    }
  }
);

export const submitTryout = createAsyncThunk(
    'addTryout/submitTryout',
    async (data) => {
      try {
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('time', data.time);
        formData.append('start_at', data.start_at);
        formData.append('end_at', data.end_at);
        formData.append('quotas', data.quotas);
        formData.append('is_quiz', data.is_quiz);
        formData.append('is_report_show', data.is_report_show);
        formData.append('instruction', data.instruction);
        formData.append('ads_video', data.ads_video);
        formData.append('tutorial_video', data.tutorial_video);
        // Handle category as objec 
        if (data.category.id) {
          formData.append('category_id', data.category.id);
        } else {
          formData.append('category_id', data.category);
        }
        data.banner && formData.append('banner', data.banner);
        let response;
        if(data.isUpdate){
          response = await TryoutService.update(data.id, formData);
        } else {
          response = await TryoutService.create(formData);
        }
        toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
        });
        data.successCallback && data.successCallback();
        return response.data.data;
      } catch (error) {
        for (const errorField in error.response.data.errors) {
          if (error.response.data.errors.hasOwnProperty(errorField)) {
            const errorMessages = error.response.data.errors[errorField];
            let errorMessage = errorMessages[0];
            toast.error(errorMessage, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
        if (error.code === "ERR_NETWORK") {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
          });
        } else if (error.response.status === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
          });
        }
      }
    }
);

export const deleteTryout = createAsyncThunk(
  'deleteTryout/deleteTryout',
  async (id) => {
    try {
      const response = await TryoutService.delete(id);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }
);

export const attachTryout = createAsyncThunk(
  'attachTryout/attachTryout',
  async (attachment) => {
    try {
      console.log('ID Tryout:', attachment.id);
      await TryoutService.attach(attachment.id, attachment);

      return true;
    } catch (error) {
      for (const errorField in error.response.data.errors) {
        if (error.response.data.errors.hasOwnProperty(errorField)) {
          const errorMessages = error.response.data.errors[errorField];
          let errorMessage = errorMessages[0];
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
      return false;
    }
  }
);

export const detachTryout = createAsyncThunk(
  'detachTryout/detachTryout',
  async (detachment) => {
    try {
      await TryoutService.detach(detachment.id, detachment);
      return true;
    } catch (error) {
      console.log('Error detachTryout:', error);
      return false;
    }
  }
);

export const replicateTryout = createAsyncThunk(
  'Tryout/replicateTryout',
  async ({ id, category_id }) => {
    try {
      const response = await TryoutService.replicate(id, category_id);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }
);
