import { createAsyncThunk } from '@reduxjs/toolkit';
import { MateriService } from '../../../../Services/axios/Materi.Service';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import { handleApiError } from '../../../../Features/Common/ErrorHandler';

export const fetchMaterial = createAsyncThunk(
  'Materi/fetchMateri',
  async (filters) => {
    try {
      const response = await MateriService.getAll(filters);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }
);

export const submitMaterial = createAsyncThunk(
  'addMateri/submitMateri',
  async (data) => {
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      const descriptionContent = data.description;
      const cleanedContent = descriptionContent.replace(/<[^>]*>/g, '').trim();
      if (cleanedContent.length > 0) {
        formData.append('description', data.description);
      }
      data.image && formData.append('image', data.image);
      // Handle subMaterials as array 
      if (data.subMaterials && data.subMaterials.length > 0){
        data.subMaterials.forEach((subMaterial, index) => {
          formData.append(`subMaterials[${index}][id]`, subMaterial.value);
        });
      }
      let response;
      if (data.isUpdate) {
        response = await MateriService.update(data.id, formData);
      } else {
        response = await MateriService.create(formData);
      }
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      data.successCallback && data.successCallback();
      return response.data.data;
    } catch (error) {
      for (const errorField in error.response.data.errors) {
        if (error.response.data.errors.hasOwnProperty(errorField)) {
          const errorMessages = error.response.data.errors[errorField];
          let errorMessage = errorMessages[0];
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
          });
        }
      }
      if (error.code === "ERR_NETWORK") {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
        });
      }
    }
  }
);

export const deleteMaterial = createAsyncThunk(
  'deleteMateri/deleteMateri',
  async (id) => {
    try {
      const response = await MateriService.delete(id);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }
);


