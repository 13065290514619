import { axiosInstance } from "./axiosInstance";

export const BlogService = {
	getAll: async (filter) => {
		return await axiosInstance.get("/api/feature/blog/admin", {
			params: filter,
		});
	},
	create: async (data) => {
		return await axiosInstance.post("/api/feature/blog", data);
	},
	update: async (id, data) => {
		return await axiosInstance.post(`/api/feature/blog/update/${id}`, data);
	},
	delete: async (id) => {
		return await axiosInstance.delete(`/api/feature/blog/delete/${id}`);
	},
};
