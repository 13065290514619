import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import { attachDetachCourses, fetchCourses } from '../../../../Features/Learning/Courses/Utils/CoursesUtils';
import { setDetail } from "../../../../Features/Learning/Courses/courseSlice";
import Swal from "sweetalert2";

const DualListBox = ({ data = [] }) => {
	const [availableOptions, setAvailableOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [isOpen, setIsOpen] = useState(true);
    const [length, setLength] = useState(0);
    const filter = useSelector(state => state.Course.filters);
    const detail = useSelector(state => state.Course.detail);
    const dispatch = useDispatch();


    useEffect(() => {
        setAvailableOptions(data);
        setLength(data.length);
    }, [data]);

	const moveRight = () => {
		const selected = availableOptions.filter(
			(option, index) => document.getElementById(`available-${index}`).checked
		);
		setSelectedOptions([...selectedOptions, ...selected]);
		setAvailableOptions(
			availableOptions.filter((option) => !selected.includes(option))
		);
		deselectAll();
	};

	const moveLeft = () => {
		const selected = selectedOptions.filter(
			(option, index) => document.getElementById(`selected-${index}`).checked
		);
		setAvailableOptions([...availableOptions, ...selected]);
		setSelectedOptions(
			selectedOptions.filter((option) => !selected.includes(option))
		);
		deselectAll();
	};

	const deselectAll = () => {
		document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
			checkbox.checked = false;
		});
	};

	const selectAllAvailable = () => {
		const isSelectAll = document.getElementById("available-all").checked;
		if (isSelectAll) {
			availableOptions.forEach((option, index) => {
				document.getElementById(`available-${index}`).checked = true;
			});
		} else {
			deselectAll();
		}
	};

	const selectAllSelected = () => {
		const isSelectAll = document.getElementById("selected-all").checked;
		if (isSelectAll) {
			selectedOptions.forEach((option, index) => {
				document.getElementById(`selected-${index}`).checked = true;
			});
		} else {
			deselectAll();
		}
	};

    const resetData = () => {
        setAvailableOptions(data);
        setSelectedOptions([]);
    }


    const handleSubmit = async () => {
        try {
            const data = {
                id: detail.id,
                materials: selectedOptions.map(option => ({ value: option.id })),
            };
            const response = await dispatch(attachDetachCourses(data));
            console.log('response:', response);
            if (response.payload === true) {
                setIsOpen(!isOpen);
                resetData();
                const getCourses = await dispatch(fetchCourses({
                    ...filter,
                    'filter[id]': detail.id,
                }));
                console.log('getCourses:', getCourses);
                dispatch(setDetail(getCourses.payload.data[0]));
                Swal.fire(
                    'Berhasil!',
                    'Tambah materi ke course.',
                    'success'
                )
            }
        } catch (error) {
            // Tanggapan error
            console.error('Error:', error);
            // Modal tidak akan ditutup jika terjadi kesalahan
        } 
    };

	return (
		<>
			<Button
				color="primary"
				onClick={() => setIsOpen(!isOpen)}
				size="sm"
				title="Edit Order">
				<i className="fa fa-exchange"></i>
			</Button>
            {}
			<Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="xl">
				<ModalHeader toggle={() => setIsOpen(!isOpen)}>Edit Order</ModalHeader>
				<ModalBody>
					<div className="container">
						<div className="row">
							<div className="col-md-5">
								<h5>Urutan Lama</h5>
								<ul className="list-group">
									<li className="list-group-item">
										<input
											type="checkbox"
											id="available-all"
											className="form-check-input me-2"
											onClick={selectAllAvailable}
										/>
										<label htmlFor="available-all" className="text-secondary">
											Select All
										</label>
									</li>
									{availableOptions.map((option, index) => (
										<li className="list-group-item" key={index}>
											<input
												type="checkbox"
												id={`available-${index}`}
												className="form-check-input me-2"
											/>
											<label htmlFor={`available-${index}`}>
												{option.name}
											</label>
										</li>
									))}
								</ul>
							</div>

							{/* Buttons */}
							<div className="col-md-2 d-flex flex-column justify-content-center align-items-center">
								<button
									className="btn btn-primary mb-2"
									onClick={moveRight}
									disabled={availableOptions.length === 0}>
									&gt;&gt;
								</button>
								<button
									className="btn btn-secondary"
									onClick={moveLeft}
									disabled={selectedOptions.length === 0}>
									&lt;&lt;
								</button>
							</div>

							{/* Selected Options */}
							<div className="col-md-5">
								<h5>Urutan Baru</h5>
								<ul className="list-group">
									<li className="list-group-item">
										<input
											type="checkbox"
											id="selected-all"
											className="form-check-input me-2"
											onClick={selectAllSelected}
										/>
										<label htmlFor="selected-all" className="text-secondary">
											Select All
										</label>
									</li>
									{selectedOptions.map((option, index) => (
										<li className="list-group-item" key={index}>
											<input
												type="checkbox"
												id={`selected-${index}`}
												className="form-check-input me-2"
											/>
											<label htmlFor={`selected-${index}`}>{option.name}</label>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => setIsOpen(!isOpen)}>
						Cancel
					</Button>
					<Button color="primary" onClick={handleSubmit} disabled={selectedOptions.length !== length}>
						Save
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default DualListBox;
