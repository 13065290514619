import { createAsyncThunk } from '@reduxjs/toolkit';
import { PrivateServices } from '../../../../../Services/axios/Private.Service';
import { checkFilter } from '../../../../Common/CommonUtils';
import { handleApiError } from '../../../../Common/ErrorHandler';

export const fetchPrivateSchedules = createAsyncThunk(
    'PrivateSchedules/fetchPrivateSchedules',
    async (filters) => {
        try {
            let filter = checkFilter(filters);
            const response = await PrivateServices.getAll(filter);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    }
);