import { createAsyncThunk } from "@reduxjs/toolkit";
import { TentorServices } from "../../../../Services/axios/Tentor.Service";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { handleApiError } from '../../../../Features/Common/ErrorHandler';

export const fetchTentors = createAsyncThunk(
	"Tentors/fetchTentors",
	async (filters) => {
		try {
			const response = await TentorServices.getAll(filters);
			return response.data;
		} catch (error) {
			handleApiError(error);
		}
	}
);

export const submitTentor = createAsyncThunk(
	"Tentors/submitTentor",
	async (data) => {
		try {
			const formData = new FormData();
			formData.append("name", data.name);
			formData.append('email', data.email);
			formData.append('status', data.status);
			formData.append("phone", data.phone);
			formData.append("mastered_fields", data.mastered_fields);
			formData.append('password', data.password);
			data.photo && formData.append("photo", data.photo);
			let response;
			if (data.isUpdate) {
				response = await TentorServices.update(data.id, formData);
				toast.success("Tentor berhasil diperbarui", {
					position: toast.POSITION.TOP_RIGHT,
				});
			} else {
				response = await TentorServices.create(formData);
				toast.success("Tentor berhasil ditambahkan", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
			data.successCallback && data.successCallback();
			return response.data.data;
		} catch (error) {
			console.log(error);
			for (const errorField in error.response.data.errors) {
				if (error.response.data.errors.hasOwnProperty(errorField)) {
					const errorMessages = error.response.data.errors[errorField];
					let errorMessage = errorMessages[0];
					toast.error(errorMessage, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 3000,
					});
				}
			}
			if (error.code === "ERR_NETWORK") {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Permintaan gagal. Mohon periksa koneksi internet Anda.",
				});
			} else if (error.response.status === 500) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Terjadi kesalahan sistem. Mohon hubungi pengembang.",
				});
			}
		}
	}
);

export const deleteTentor = createAsyncThunk(
	"Tentors/deleteTentor",
	async (id) => {
		try {
			const response = await TentorServices.delete(id);
			return response.data.data;
		} catch (error) {
			if (error.response && error.response.data) {
				toast.error(error.response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
	}
);
