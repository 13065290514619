import React, { Fragment, useEffect } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import BlogContain from "../../Component/Blog/BlogContain";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
	setField,
	resetState,
} from "../../Features/Learning/Blog/addBlogSlice";
import { fetchBlog } from "../../Features/Learning/Blog/Utils/BlogUtils";
import { handleEditBlog } from "../../Component/Blog/BlogUtils";

const BlogAdd = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const addField = useSelector((state) => state.addBlog);
	const filters = useSelector((state) => state.Blog.filters);

	useEffect(() => {
		if (!addField.id && id) {
			const newFilters = {
				...filters,
				"filter[id]": id,
				page: 1,
			};
			dispatch(fetchBlog(newFilters))
				.then((response) => {
					// Kode yang akan dijalankan setelah fetch sukses
					handleEditBlog(dispatch, response.payload.data[0], setField);
				})
				.catch((error) => {
					// Tangani error jika fetch gagal
					console.error("Error during fetchBlog:", error);
				});
		} else if (!id) {
			dispatch(resetState());
			console.log(addField, "addField");
		}
	}, [id]);

	return (
		<Fragment>
			<Breadcrumbs parent="Blog" title={id ? "Update Blog" : "Tambah Blog"} />
			<BlogContain />
		</Fragment>
	);
};

export default BlogAdd;
