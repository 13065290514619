import { createAsyncThunk } from '@reduxjs/toolkit';
import { TicketServices } from '../../../../Services/axios/Ticket.Service';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { handleApiError } from '../../../../Features/Common/ErrorHandler';

export const fetchTickets = createAsyncThunk(
    'Tickets/fetchTickets',
    async (filters) => {
        try {
            const response = await TicketServices.getAll(filters);
            return response.data;
        } catch (error) {
            handleApiError(error);
        }
    }
);

export const markAsSolved = createAsyncThunk(
    'Tickets/markAsSolved',
    async (data) => {
        try {
            const formData = new FormData();
            formData.append('feedback', data.feedback);

            let response;
            response = await TicketServices.markAsSolved(data.id, formData);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.log(error.response.data);
            }
        }
    }
);