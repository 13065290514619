import { createAsyncThunk } from "@reduxjs/toolkit";
import { MainProgramService } from "../../../../Services/axios/MainProgram.service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { handleApiError } from '../../../../Features/Common/ErrorHandler';

export const fetchMainProgram = createAsyncThunk(
    "MainPrograms/fetchMainPrograms",
    async (filters) => {
        try {
        const response = await MainProgramService.getAll(filters);
        return response.data;
        } catch (error) {
            handleApiError(error);
        }
    }
    );

export const submitMainProgram = createAsyncThunk(
    "addMainProgram/submitMainProgram",
    async (data) => {
        try {
        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("batch", data.batch);
        formData.append("header_desc", data.header_desc);
        formData.append("content_desc", data.content_desc);
        formData.append("video_promo", data.video_promo);
        if (data.point.length > 0) {
            formData.append("point", JSON.stringify(data.point));
        } else {
            formData.append("point", JSON.stringify([]));
        }
        formData.append("is_tryout_only", data.is_tryout_only);
        formData.append("is_private", data.is_private);
        if (data.slug !== data.old_slug && data.is_campus_event == 1) { 
            formData.append("slug", data.slug || "delete");
        } else if (data.is_campus_event == 0) {
            formData.append("slug", "delete");
        }
        data.image && formData.append("image", data.image);
        data.attachment && formData.append("attachment", data.attachment);
        if (data.category_id.id) {
          formData.append('category_id', data.category_id.id);
        } else {
          formData.append('category_id', data.category_id);
        }
        formData.append("order", data.order);
        let response;
        if (data.isUpdate) {
            response = await MainProgramService.update(data.id, formData);
        } else {
            response = await MainProgramService.create(formData);
        }
        Swal.fire({
            icon: 'success',
            title: 'Sukses',
            text: data.isUpdate ? 'Program berhasil diubah.' : 'Program berhasil ditambahkan.',
        });
        data.successCallback && data.successCallback();
        return response.data.data;
        } catch (error) {
            for (const errorField in error.response.data.errors) {
                if (error.response.data.errors.hasOwnProperty(errorField)) {
                const errorMessages = error.response.data.errors[errorField];
                let errorMessage = errorMessages[0];
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                }
            }
            if (error.code === "ERR_NETWORK") {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Permintaan gagal. Mohon periksa koneksi internet Anda.',
                });
            } else if (error.response.status === 500) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Terjadi kesalahan sistem. Mohon hubungi pengembang.',
                });
            }
        }
    }
    );

export const deleteMainProgram = createAsyncThunk(
    "MainPrograms/deleteMainProgram",
    async (id) => {
        try {
        const response = await MainProgramService.delete(id);
        return response.data.data;
        } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            });
            console.log(error.response.data);
        }
        }
    }
    );

export const attachDetachMainProgram = createAsyncThunk(
    "MainPrograms/attachDetachMainProgram",
    async (data) => {
        try {
            const formData = new FormData();
            formData.append("type", data.type);
            // jika type attach jalankan dibawah, jika detach kirim id saja tanpa looping
            data.membership_id.forEach((item, index) => {
                formData.append(`membership_id[${index}]`, item.value);
            });
            await MainProgramService.attachDetach(data.id, formData);
            return true;
        } catch (error) {
        if (error.response && error.response.data) {
            toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            });
            console.log(error.response.data);
        }
        }
    }
    );

export const replicateMainProgram = createAsyncThunk(
    "MainPrograms/replicateMainProgram",
    async (id) => {
        try {
            const response = await MainProgramService.replicate(id);
            return response;
        } catch (error) {
            if (error.response && error.response.data) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.log(error.response.data);
            }
        }
    }
);