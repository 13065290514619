import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader, CardFooter, Media, Table, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, H6, H5, H4, LI, UL, P, Btn } from '../../../../AbstractElements';
import AttachModal from '../../../../Component/Learning/Common/Modal/ModalButton';
import { detachTryout } from '../../../../Features/Learning/Tryout/Utils/TryoutUtils';
import { handleDetach } from '../../../../Component/Learning/Courses/CoursesUtils';
import { setId, setMaterials } from '../../../../Features/Learning/Courses/attachDetachCourseSlice';
import { attachDetachCourses, fetchCourses } from '../../../../Features/Learning/Courses/Utils/CoursesUtils';
import { setDetail } from '../../../../Features/Learning/Courses/courseSlice';
import { fetchMaterial } from '../../../../Features/Learning/Materi/Utils/MateriUtils';
import RawComponent from '../../../../Component/Learning/Common/RawHTML';
import CleanLimitHTML from '../../../../Component/Learning/Common/GenerateContent/CleanLimitHtml';
import GenerateUniqueContain from '../../../../Component/Learning/Common/GenerateUnique';
import AsyncSelectCommon from '../../../../Component/Learning/Common/SelectOption/AsyncSelect';
import DualListBox from '../../../../Component/Learning/Common/DualListBox';
import { 
    handleGetDetailMaterial,
    handleEditMateri,
 } from '../../../../Component/Learning/Materi/MateriUtils';
import { setField as setFieldMateri } from '../../../../Features/Learning/Materi/addMaterialSlice';
import { setDetail as setDetailMateri} from '../../../../Features/Learning/Materi/materialSlice';
import Moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';

const BoxListCourseDetail = () => {
    const dispatch = useDispatch();
    const detail = useSelector(state => state.Course.detail);
    const filter = useSelector(state => state.Course.filters);
    const filtersMateri = useSelector(state => state.Material.filters);
    const data = useSelector(state => state.attachDetachCourse);
    const dataOptions = useSelector(state => state.Material.data);
    const [modal, setModal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const allIds = detail.materials ? detail.materials.map(material => ({ value: material.id })) : [];
    const filteredIds = detail.materials ? detail.materials.filter(material => !selectedMaterials.includes(material.id)) : [];
    const formatCombinedIds = Array.from(filteredIds).map(materialSelect => ({ value: materialSelect.id }));

    useEffect(() => {
        dispatch(setId(detail.id))
        // fetchMateri(dispatch)
        window.scrollTo(0, 0);
    }, [detail.id])

    const handleCheckboxChange = (materialId) => {
        console.log(materialId, 'materialId')
        if (selectedMaterials.includes(materialId)) {
            // Jika sudah ada, hapus dari selectedMaterials
            setSelectedMaterials(selectedMaterials.filter((id) => id !== materialId));
            // dispatch(setMaterials(formatCombinedIds));
        } else {
            // Jika belum ada, tambahkan ke selectedMaterials
            setSelectedMaterials([...selectedMaterials, materialId]);
            // dispatch(setMaterials(formatCombinedIds));
            // console.log('combinedIds', combinedIds)
        }
        // console.log(questionId, 'questionId')
        // const newDetail = { ...detail };
        // newDetail.question = newDetail.question.filter((question) => question.id !== questionId);
        // dispatch(setDetail(newDetail));
    };

    const handleBatchDelete = () => {
        console.log('selectedMaterials', data.materials)
        console.log(formatCombinedIds, 'formatCombinedIds')
    }

    const toggle = () => {
        setModal(!modal);
        console.log(data, 'data')
        dispatch(setId(detail.id))
        dispatch(setMaterials([]));
        setSelectedValue(null);
        setSelectedMaterials([]);
    };

    const promiseOptions = (inputValue, callback) => {
        // console.log('Input Value = ', inputValue)
        if (inputValue !== '') {
            dispatch(fetchMaterial({
                ...filtersMateri,
                'filter[name]': inputValue,
                'sort': 'name',
                'page': 1,
            }))
                .then((response) => {
                    const options = response.payload.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    }));
                    callback(options);
                })
                .catch((error) => {
                    console.error('Error fetching options:', error);
                    callback([]);
                });
        }
    };

    const handleSelectChange = (selectedOption) => {
      setSelectedValue(selectedOption);
      console.log(selectedOption, 'selectedOption')
      if (selectedOption === null) {
        dispatch(setMaterials([])); 
      } else {
        // Menggabungkan semua id yang ada di "allIds" dan "selectedOption"
        const combinedIdsSet = new Set([...allIds.map(item => item.value), ...selectedOption.map(item => item.value)]);
        // Mengubah Set menjadi Array
        const combinedIds = Array.from(combinedIdsSet).map(value => ({ value: value }));
        dispatch(setMaterials(combinedIds));
        // console.log('combinedIds', combinedIds)
      }
    };

    const handleSubmit = async () => {
        try {
            const response = await dispatch(attachDetachCourses(data));
            console.log('response:', response);
            // console.log('response.meta.arg.id:', response.meta.arg.id);
            // console.log('detailTryout:', detailTryout.id);
            if (response.payload === true) {
                setModal(!modal);
                const getCourses = await dispatch(fetchCourses({
                    ...filter,
                    'filter[id]': detail.id,
                }));
                console.log('getCourses:', getCourses);
                // const questionData = getCourses.payload.data.filter(item => item.id === detail.id);
                // console.log('Sukses tutup modal:', questionData[0]);
                dispatch(setDetail(getCourses.payload.data[0]));
                Swal.fire(
                    'Berhasil!',
                    'Tambah materi ke course.',
                    'success'
                )
            }
        } catch (error) {
            // Tanggapan error
            console.error('Error:', error);
            // Modal tidak akan ditutup jika terjadi kesalahan
        }
    };

    const history = useNavigate();
    const [refatch, setRefatch] = useState(false)


    return (
        <Fragment>
            <Col sm="12" xl="12" lg="12" className="xl-100 box-col-12 order-1">
                <Card className="social-widget-card">
                    <CardBody>
                        <Col>
                            <Row>
                                <Col>
                                    <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>{detail.title || 'Title Course'}</H5>
                                </Col>
                                <Col className='text-end'>
                                    <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}>ID Course : {GenerateUniqueContain('CRS', detail.id, 5) || 'ID Course'}</H5>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <H6 attrH6={{ className: 'font-roboto mt-4 font-primary' }}>{'Deskripsi Course'}</H6>
                            <H5 attrH5={{ className: 'counter mb-0 font-roboto font-primary mt-2' }}><RawComponent htmlContent={detail.description || 'Description Course'} /></H5>
                        </Col>
                        <Col>
                            <H6 attrH6={{ className: 'font-roboto mt-4 mb-3 font-primary' }}>{'Banner Course'}</H6>
                            <div className="blog-page blog-box">
                                <img style={{ width: "30%" }} src={detail.banner} alt={detail.title} />
                            </div>
                        </Col>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col>
                                <H6 attrH6={{ className: 'font-roboto' }}>Created at: {Moment(detail.created_at).format('dddd, D MMMM YYYY')} - {Moment(detail.created_at).format('HH:mm:ss')} WIB</H6>
                            </Col>
                            <Col className='text-end'>
                                <Link to={`${process.env.PUBLIC_URL}/membership/course-list`}>
                                    <Btn attrBtn={{ color: 'warning', className: 'btn btn-primary m-r-10' }}>
                                        <i className="fa fa-chevron-circle-left fa-lg me-2"></i>Back
                                    </Btn>
                                </Link>
                                <AttachModal colorBtn={'primary'} item={detail} buttonLabel={'Tambah Materi'} toggle={toggle} modalLabel={'Tambah Materi Course'} modal={modal} handleSubmit={handleSubmit} >
                                    <AsyncSelectCommon label={'Materi'} placeholder={'Silahkan pilih materi...'} 
                                    isMulti={true}
                                    promiseOptions={promiseOptions}
                                    handleSelectChange={handleSelectChange}
                                    selectedValue={selectedValue}
                                    />
                                </AttachModal>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Col>
            {detail.materials && detail.materials.length > 0 && (
                <Col sm="12 order-2">
                    <Card>
                        <CardHeader>
                            <H5>List Materi Course {detail.title}</H5>
                        </CardHeader>
                        <div>
                            <Table className="table-responsive table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col"><DualListBox data={detail.materials} /></th>
                                        <th scope="col">{'Judul Materi'}</th>
                                        <th scope="col">{'Deskripsi Materi'}</th>
                                        <th scope="col">{'ID'}</th>
                                        <th colSpan="2" scope="col" className='text-center'>Aksi</th>
                                        <th scope="col" className='text-center'>
                                            <Btn
                                                attrBtn={{
                                                    color: 'link',
                                                    outline: true,
                                                    className: 'btn-sm btn-danger',
                                                    onFocus: () => {
                                                        // console.log(formatCombinedIds, 'formatCombinedIds')
                                                        // console.log(filteredIds, 'filteredIds')
                                                        dispatch(setMaterials(formatCombinedIds));
                                                    },
                                                    onClick: () => {
                                                        console.log('selectedMaterials', selectedMaterials)
                                                        if (selectedMaterials.length > 0 && detail.materials.length === 1) {
                                                            Swal.fire(
                                                                'Gagal!',
                                                                'Course harus memiliki minimal 1 materi.',
                                                                'error'
                                                            )
                                                        } else if (selectedMaterials.length === detail.materials.length) {
                                                            Swal.fire(
                                                                'Gagal!',
                                                                'Course harus memiliki minimal 1 materi.',
                                                                'error'
                                                            )
                                                        } else if (selectedMaterials.length === 0 && detail.materials.length > 1) {
                                                            Swal.fire(
                                                                'Gagal!',
                                                                'Silahkan pilih materi yang akan dihapus.',
                                                                'error'
                                                            )
                                                        } else if (selectedMaterials.length === 0 && detail.materials.length === 1) {
                                                            Swal.fire(
                                                                'Gagal!',
                                                                'Course harus memiliki minimal 1 materi.',
                                                                'error'
                                                            )
                                                        } else if (selectedMaterials.length > 0 && detail.materials.length > 1) {
                                                            handleDetach(detail, data, dispatch, attachDetachCourses, setDetail, selectedMaterials)
                                                        } 
                                                    }
                                                }}
                                            >Delete
                                            </Btn>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        detail.materials && detail.materials.map((item, index) =>
                                            <tr key={`course-${index}`}>
                                                <th className='text-center'>{index + 1}</th>
                                                <td>{item.name}</td>
                                                <td><CleanLimitHTML htmlContent={item.description} limit={40} /></td>
                                                <td>{item.id}</td>
                                                <td className='text-center'>
                                                    <Link to={`${process.env.PUBLIC_URL}/learning/materi-detail/${item.id}`}
                                                        onClick={() => handleGetDetailMaterial(dispatch, item, setDetailMateri)}
                                                    >
                                                        <i className="fa fa-info-circle mx-auto fa-lg"></i>
                                                    </Link>
                                                </td>
                                                <td className='text-center'>
                                                    <Link to={`${process.env.PUBLIC_URL}/learning/materi-add/${item.id}`} 
                                                        onClick={() => handleEditMateri(dispatch, item, setFieldMateri)}
                                                    >
                                                        <i className="fa fa-edit mx-auto text-black fa-lg"></i>
                                                    </Link>
                                                </td>
                                                <td className='text-center'>
                                                    <Input className="checkbox_animated" id={`detach-${item.id}`} type="checkbox" 
                                                    checked={selectedMaterials.includes(item.id)}
                                                    onChange={() => handleCheckboxChange(item.id)} />
                                                    
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </Col>
            )
            }
        </Fragment >
    );
};
export default BoxListCourseDetail;