import { createAsyncThunk } from "@reduxjs/toolkit";
import { BlogService } from "../../../../Services/axios/Blog.Service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { handleApiError } from '../../../../Features/Common/ErrorHandler';
import { checkFilter } from '../../../../Features/Common/CommonUtils';

export const fetchBlog = createAsyncThunk("Blog/fetchBlog", async (filters) => {
	try {
		let filter = checkFilter(filters);
		const response = await BlogService.getAll(filter);
		return response.data;
	} catch (error) {
		handleApiError(error);
	}
});

export const submitBlog = createAsyncThunk(
	"addBlog/submitBlog",
	async (data) => {
		try {
			const formData = new FormData();
			data.title && formData.append("title", data.title);
			data.description && formData.append("description", data.description);
			data.thumbnail && formData.append("thumbnail", data.thumbnail);
			data.link && formData.append("link", data.link);
			data.category && formData.append("category", data.category);
			formData.append("is_hide", data.is_hide);
			formData.append("order", data.order);
			if (data.tags) {
				// convert tags from stirng to array, split by comma
				const tags = data.tags.split(",");
				tags.forEach((tag) => {
					formData.append("tags[]", tag);
				});
			}
			if (data.old_slug !== data.slug) {
				data.slug && formData.append("slug", data.slug);
			}
			let response;
			if (data.isUpdate) {
				response = await BlogService.update(data.id, formData);
			} else {
				response = await BlogService.create(formData);
			}
			toast.success(response.data.message, {
				position: toast.POSITION.TOP_RIGHT,
			});
			data.successCallback && data.successCallback();
			return response.data.data;
		} catch (error) {
			for (const errorField in error.response.data.errors) {
				if (error.response.data.errors.hasOwnProperty(errorField)) {
					const errorMessages = error.response.data.errors[errorField];
					let errorMessage = errorMessages[0];
					toast.error(errorMessage, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 3000,
					});
				}
			}
			if (error.code === "ERR_NETWORK") {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Permintaan gagal. Mohon periksa koneksi internet Anda.",
				});
			} else if (error.response.status === 500) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Terjadi kesalahan sistem. Mohon hubungi pengembang.",
				});
			}
		}
	}
);

export const deleteBlog = createAsyncThunk(
	"deleteBlog/deleteBlog",
	async (id) => {
		try {
			const response = await BlogService.delete(id);
			toast.success(response.data.message, {
				position: toast.POSITION.TOP_RIGHT,
			});
			return response.data.data;
		} catch (error) {
			if (error.response && error.response.data) {
				toast.error(error.response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
	}
);
